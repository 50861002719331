import React, { useState, useEffect } from "react";
import tw, { styled } from "twin.macro";
import { navigate } from "gatsby";

import ContactForm from "../components/ContactForm";
import SvgSpinner from "../components/SvgSpinner";
import QuoteForm from "../components/QuoteForm";

const Container = styled.div`
  ${tw`
    fixed
    h-screen w-screen
    top-0 left-0
    flex justify-center items-center
    `}

  background-color: rgba(50, 50, 50, 0.5);
  min-height: 30rem;
  z-index: 101;
`;

const Card = styled.div`
  ${tw`
    shadow-lg
    transition-all duration-200
    w-full max-h-full overflow-y-auto relative
    bg-gray-100
    max-w-full 
    `}

  ${({ isGeneral }) => (isGeneral ? tw`sm:max-w-lg` : tw`sm:max-w-3xl`)}

  z-index: 3;
`;

const ContentContainer = styled.div`
  ${tw`
      flex flex-col 
      w-full h-full 
      p-6 sm:p-8
      bg-gray-100
  `}
  z-index: 2;
`;

const CloseButton = styled.span`
  ${tw`
    absolute block
    top-5 right-5
    h-8 w-8
    cursor-pointer
    `}

  &::before,
    &::after {
    ${tw`
        absolute
        w-8
        top-1/2 left-1/2
    `}

    content: "";
    height: 2px;
    background: #404040;
  }
  &::before {
    ${tw`
        transform 
        -translate-x-1/2 -translate-y-1/2 
        rotate-45
    `}
  }
  &::after {
    ${tw`
        transform 
        -translate-x-1/2 -translate-y-1/2 
        -rotate-45
    `}
  }
`;

const SpinnerContainer = styled.div`
  ${tw`
        absolute
        left-0 right-0
        mx-auto
    `}

  width: 100px;
  z-index: 4;
`;

const Error = styled.div`
  ${tw`text-red-600 text-center mt-2`}
`;

const EmailController = ({
  showEmail,
  setShowEmail,
  type,
  preSelectedServices,
}) => {
  const [sent, setSent] = useState({
    status: false,
    loading: false,
    errorMsg: "",
  });

  const closeEmailModal = () => {
    setSent({ status: false, loading: false, errorMsg: "" });
    setShowEmail(false);
  };

  useEffect(() => {
    //If the modal is visible, prevents scrolling of the anything else other than the modal
    showEmail
      ? document.body.classList.add("hide-vertical-scroll")
      : document.body.classList.remove("hide-vertical-scroll");

    //Creating an event listener which will call the function escFunction anytime a key is pressed.
    document.addEventListener("keydown", (e) =>
      e.key === "Escape" ? setShowEmail(false) : null
    );

    //Removing the event listener attached to keypresses
    return document.removeEventListener("keydown", (e) =>
      e.key === "Escape" ? setShowEmail(false) : null
    );
  });

  useEffect(() => {
    let { status, errorMsg } = sent;
    if (status && errorMsg.length === 0) {
      navigate("/thank-you");
      closeEmailModal();
    }
  }, [sent]);

  if (!showEmail) return null;
  return (
    <Container>
      <Card isGeneral={type == "general"}>
        <CloseButton onClick={closeEmailModal} />
        <ContentContainer>
          {type === "general" && (
            <ContactForm
              setSent={setSent}
              sent={sent}
              title="Send us an email"
              formType="General"
            />
          )}
          {type === "quote" && (
            <QuoteForm
              setSent={setSent}
              sent={sent}
              title="Get A Free Quote"
              formType="Quote"
              preSelectedServices={preSelectedServices}
            />
          )}
          {sent.status && sent.errorMsg.length > 0 && (
            <Error>{sent.errorMsg}</Error>
          )}
        </ContentContainer>
      </Card>
      {sent.loading && (
        <SpinnerContainer>
          <SvgSpinner />
        </SpinnerContainer>
      )}
    </Container>
  );
};

export default EmailController;
