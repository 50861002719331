import React, { createContext, useState } from "react";
import EmailController from "../controllers/EmailController";

export const EmailContext = createContext();

export const EmailContextProvider = ({ children }) => {
  const [showEmail, setShowEmail] = useState(false);
  const [type, setType] = useState("general"); // <"general" | "quote">
  const [preSelectedServices, setPreSelectedServices] = useState([]);

  return (
    <EmailContext.Provider
      value={{ showEmail, setShowEmail, type, setType, setPreSelectedServices }}
    >
      {children}
      <EmailController
        showEmail={showEmail}
        setShowEmail={setShowEmail}
        type={type}
        preSelectedServices={preSelectedServices}
      />
    </EmailContext.Provider>
  );
};
