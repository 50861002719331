import React from "react"
import tw, { styled } from 'twin.macro'

const Btn = styled.button`
    ${tw`
        items-center rounded 
        cursor-pointer font-bold
        py-2 px-4
        transition-all duration-200
        bg-gray-400
        hover:bg-gray-500
        text-white
        text-lg 
    `}
`

const Button = ({ children, handleClick, className }) => (
  <Btn onClick={handleClick} className={className}>
    {children}
  </Btn>
)

export default Button
