import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import Button from "./Button";

const InputText = styled.input`
  ${tw`
      block border-none rounded
      py-3 px-4
      w-full 
  `}

  :hover, :focus {
    ${tw`
        outline-none
        bg-gray-200
    `}
  }
`;

const TextArea = styled.textarea`
  ${tw`
      block border-none rounded
      py-3 px-4
      w-full resize-none
  `}

  height: 150px;

  :hover,
  :focus {
    ${tw`
        outline-none
        bg-gray-200
    `}
  }
`;

const Container = styled.form`
  ${tw`
      flex flex-col 
      w-full h-full 
      p-6 sm:p-8
      bg-gray-100
  `}
  z-index: 2;
`;

const Secondary = styled.h6`
  ${tw`text-xl font-medium mb-4`}
`;

const FormGrid = styled.div`
  ${tw`grid gap-4`}
`;

const SubmitBtn = styled(Button)`
  ${tw`my-4 w-full`}

  background-color: orange;

  :hover {
    background-color: #e69500;
  }
`;

const TelLink = styled.a`
  ${tw`underline`}

  :hover {
    text-decoration-color: darkorange;
  }
`;

const ContactForm = ({ title, sent, setSent, formType }) => {
  const [details, setDetails] = useState({ formType: `${formType} inquiry` });
  function handleChange(event) {
    setDetails({ ...details, [event.target.name]: event.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (e.target.disabled) {
        return;
      }

      // let { formType, ...formFields } = details
      e.target.disabled = true;

      setSent({ ...sent, loading: true });

      const res = await fetch(
        "https://us-central1-lt3-sendgrid-mailer.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...details }),
        }
      );
      if (res.status === 200) {
        setDetails({});
        setSent({ status: true, loading: false, errorMsg: "" });
      } else {
        setSent({
          status: true,
          loading: false,
          errorMsg: "Oops, something went wrong. Please try again.",
        });
      }
    } catch (error) {
      console.log(error);
      setSent({
        status: true,
        loading: false,
        errorMsg: "Oops, something went wrong. Please try again later.",
      });
    }
  }

  // TEST FUNCTION TO SEE SUCCESS MODAL
  // function test() {
  //     console.log("TEST CLICKED");
  //     setSent({ ...sent, status: true });
  // }

  return (
    <form onSubmit={handleSubmit}>
      <Secondary>{title}</Secondary>
      <FormGrid>
        <InputText
          id="name"
          placeholder="Name"
          type="text"
          name="name"
          margin="normal"
          value={details.name || ""}
          onChange={handleChange}
          required
        />
        <InputText
          id="email"
          placeholder="Email"
          type="email"
          name="email"
          margin="normal"
          value={details.email || ""}
          onChange={handleChange}
          required
        />
        <TextArea
          id="body"
          name="body"
          placeholder="Message"
          multiline
          rowsMax="4"
          margin="normal"
          value={details.body || ""}
          onChange={handleChange}
          required
        />
      </FormGrid>
      <SubmitBtn type="submit">Send</SubmitBtn>
      <div tw="text-center">
        <span>Prefer to call us? </span>
        <TelLink href="tel:8005906138">(800) 590-6138</TelLink>
      </div>
      {/* <Button handleClick={test}>Test</Button> */}
    </form>
  );
};

export default ContactForm;
