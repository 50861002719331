import React from "react"
import { EmailContextProvider } from "./src/contexts/EmailContext"
import "./src/styles/font/font.css"

export const wrapRootElement = ({ element }) => {
    return (
        <EmailContextProvider>
            {element}
        </EmailContextProvider>
    )
}