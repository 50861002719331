import React, { useEffect, useState } from "react";
import tw, { styled, css } from "twin.macro";
import Button from "./Button";
import { toKebabCase } from "../utils";

const InputText = styled.input`
  ${tw`
      block border-none rounded
      py-3 px-4
      w-full 
  `}

  :hover, :focus {
    ${tw`
        outline-none
        bg-gray-200
    `}
  }
`;

const TextArea = styled.textarea`
  ${tw`
      block border-none rounded
      py-3 px-4
      w-full resize-none
      text-sm
  `}

  height: 100%;

  :hover,
  :focus {
    ${tw`
        outline-none
        bg-gray-200
    `}
  }
`;

const Container = styled.form`
  ${tw`
      flex flex-col 
      w-full h-full 
      p-6 sm:p-8
      bg-gray-100
  `}
  z-index: 2;
`;

const Secondary = styled.h6`
  ${tw`text-xl font-medium mb-6`}
`;

const FormGrid = styled.div`
  ${tw`grid gap-4 sm:grid-cols-2`}
`;

const Group = styled.div`
  ${tw`flex gap-y-4 flex-col`}
`;

const ServiceGroup = styled.div`
  ${tw`
    flex flex-wrap justify-center 
    -ml-1 w-[calc(100% + 0.5rem)]
  `}
`;

const ServiceBtn = styled(Button)`
  ${tw`
    bg-[orange] hover:bg-[#e69500]
    duration-75 text-sm
    border-2 border-[orange] hover:border-[#e69500]
    flex gap-1 justify-between
    px-2 m-1 
    w-[calc(50% - 0.5rem)] md:w-[calc(33.33% - 0.5rem)]
  `}

  > img {
    filter: brightness(0%) invert(1);
    ${tw`h-6 w-6`}
  }

  ${({ selected }) =>
    selected &&
    css`
      > img {
        filter: brightness(100%);
      }
      ${tw`bg-white text-[darkorange] border-[darkorange] hover:bg-[#fff6e6]`}
    `}
`;

const ServiceBtnCheck = styled.span`
  ${tw`invisible text-green-700`}

  ${({ visible }) => visible && tw`visible`}
`;

const SubmitBtn = styled(Button)`
  ${tw`mt-6 w-full bg-black hover:bg-gray-800`}
`;

const TelLink = styled.a`
  ${tw`underline`}

  :hover {
    text-decoration-color: darkorange;
  }
`;
const InputWrapper = styled.div`
  ${tw`flex flex-col gap-y-2`}
`;

const Select = styled.select`
  ${tw`py-3 px-4 rounded`}
`;

const Label = styled.div`
  ${tw`text-sm`}
`;

const Error = styled.span`
  ${tw`text-red-500 text-sm`}
`;

const CheckBoxContainer = styled.div`
  grid-template-columns: 1em auto;
  ${tw`grid gap-2 my-1.5`}
  > input {
    ${tw`h-4 w-4 mt-0.5`}
  }
  > label {
    ${tw`text-sm`}
  }
`;

const SERVICE_MAP = [
  {
    label: "App Development",
    icon: "/img/svg/nav/app-development.svg",
  },
  {
    label: "Website Development",
    icon: "/img/svg/nav/web-development-orange.svg",
  },
  {
    label: "Digital Marketing",
    icon: "/img/svg/nav/digital-advertising-orange.svg",
  },
  {
    label: "Social Media Management",
    icon: "/img/svg/nav/social-media-management-orange.svg",
  },
  {
    label: "Container Deposit Systems",
    icon: "/img/svg/nav/cds.svg",
  },
  {
    label: "Extended Producer Responsibility",
    icon: "/img/svg/nav/epr.svg",
  },
  {
    label: "Software Consulting",
    icon: "/img/svg/nav/software-consulting.svg",
  },
  {
    label: "Beverage Container Image Processing",
    icon: "/img/svg/nav/bcip.svg",
  },
  {
    label: "Commodity Tracking & Collection",
    icon: "/img/svg/nav/commodity-tracking.svg",
  },
  {
    label: "ChatGPT/LLM",
    icon: "/img/svg/nav/chatbot.svg",
  },
  {
    label: "Image Recognition",
    icon: "/img/svg/nav/computer-vision.svg",
  },
];

const QuoteForm = ({
  title,
  sent,
  setSent,
  formType,
  preSelectedServices = [],
}) => {
  const [details, setDetails] = useState({
    formType: `${formType} inquiry`,
    // services: [],
  });
  const [errorMsg, setErrorMsg] = useState("");
  function handleChange(event) {
    setDetails({ ...details, [event.target.name]: event.target.value });
  }

  // useEffect(() => {
  //   setDetails({ ...details, services: preSelectedServices });
  // }, [preSelectedServices]);

  // function handleCheck(event) {
  //   const { value } = event.target;
  //   const isAlreadyChecked = details.services.includes(value);
  //   let newSelectedServices;
  //   if (isAlreadyChecked) {
  //     // uncheck
  //     newSelectedServices = details.services.filter((item) => item !== value);
  //   } else {
  //     newSelectedServices = [...details.services, value];
  //   }
  //   setDetails({ ...details, services: newSelectedServices });
  //   if (errorMsg && newSelectedServices.length !== 0) {
  //     setErrorMsg("");
  //   }
  // }

  function handleSelectService(event, service) {
    event.preventDefault();

    const isAlreadyChecked = details.services.includes(service);
    let newSelectedServices;
    if (isAlreadyChecked) {
      // uncheck
      newSelectedServices = details.services.filter((item) => item !== service);
    } else {
      newSelectedServices = [...details.services, service];
    }
    setDetails({ ...details, services: newSelectedServices });
    if (errorMsg && newSelectedServices.length !== 0) {
      setErrorMsg("");
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      // if (details.services.length === 0) {
      //   setErrorMsg("Select at least of one the following:");
      //   return;
      // }

      if (e.target.disabled) {
        return;
      }

      // let { formType, ...formFields } = details
      e.target.disabled = true;

      setSent({ ...sent, loading: true });

      const res = await fetch(
        "https://us-central1-lt3-sendgrid-mailer.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...details }),
        }
      );
      if (res.status === 200) {
        setDetails({});
        setSent({ status: true, loading: false, errorMsg: "" });
      } else {
        setSent({
          status: true,
          loading: false,
          errorMsg: "Oops something went wrong. Please try again.",
        });
      }
    } catch (error) {
      console.log(error);
      setSent({
        status: true,
        loading: false,
        errorMsg: "Oops something went wrong. Please try again later.",
      });
    }
  }

  // TEST FUNCTION TO SEE SUCCESS MODAL
  // function test() {
  //     console.log("TEST CLICKED");
  //     setSent({ ...sent, status: true });
  // }

  return (
    <form onSubmit={handleSubmit}>
      <Secondary>{title}</Secondary>
      <FormGrid>
        <InputText
          id="name"
          placeholder="Name"
          type="text"
          name="name"
          margin="normal"
          value={details.name || ""}
          onChange={handleChange}
          required
        />
        <InputText
          id="company"
          placeholder="Company"
          type="text"
          name="company"
          margin="normal"
          value={details.company || ""}
          onChange={handleChange}
          required
        />
        <InputText
          id="email"
          placeholder="Email"
          type="email"
          name="email"
          margin="normal"
          value={details.email || ""}
          onChange={handleChange}
          required
        />
        <InputText
          id="phone"
          placeholder="Phone"
          type="tel"
          name="phone"
          margin="normal"
          value={details.phone || ""}
          onChange={handleChange}
          required
        />
        <Group>
          <InputWrapper>
            <Label>Company Type</Label>
            <Select
              id="company-type"
              name="company-type"
              onChange={handleChange}
              value={details["company-type"] || ""}
              required
            >
              <option value="">-- Select A Type --</option>
              <option value="Startup">Startup</option>
              <option value="Small or Medium-Sized Business">
                Small or Medium-Sized Business
              </option>
              <option value="Enterprise">Enterprise</option>
            </Select>
          </InputWrapper>

          <InputWrapper>
            <Label>Budget</Label>
            <Select
              id="budget"
              name="budget"
              onChange={handleChange}
              value={details.budget || ""}
              required
            >
              <option value="">-- Select A Range --</option>
              <option value="Under $100,000">Under $100,000</option>
              <option value="$100,000 - $250,000">$100,000 - $250,000</option>
              <option value="$250,000 - $500,000">$250,000 - $500,000</option>
              <option value="$500,000 and above">$500,000 and above</option>
            </Select>
          </InputWrapper>

          <InputWrapper>
            <Label>How did you find us?</Label>
            <InputText
              id="referral"
              placeholder=""
              type="text"
              name="referral"
              margin="normal"
              value={details.referral || ""}
              onChange={handleChange}
              required
            />
          </InputWrapper>
        </Group>

        <InputWrapper>
          <Label>Additional Information</Label>
          <TextArea
            id="additional-info"
            name="additional-info"
            multiline
            rowsMax="4"
            margin="normal"
            value={details["additional-info"] || ""}
            onChange={handleChange}
          />
        </InputWrapper>

        {/* <InputWrapper tw="col-span-full"> */}
          {/* <div>
            <Label>I'm looking for:</Label>
            {errorMsg && <Error>{errorMsg}</Error>}
          </div> */}
          {/* <ServiceGroup> */}
            {/* {SERVICE_MAP.map((service) => (
                <CheckBoxContainer>
                  <input
                    type="checkbox"
                    id={toKebabCase(service)}
                    name="services"
                    value={service}
                    checked={details.services.includes(service)}
                    onChange={handleCheck}
                  />
                  <label htmlFor={toKebabCase(service)}>{service}</label>
                </CheckBoxContainer>
              ))} */}
            {/* {SERVICE_MAP.map(({ label, icon }) => {
              const services = details.services || [];
              const isSelected = services.includes(label);
              return (
                <ServiceBtn
                  selected={isSelected}
                  type="button"
                  handleClick={(e) => handleSelectService(e, label)}
                >
                  <img src={icon} alt="icon" />
                  {label}
                  <ServiceBtnCheck visible={isSelected}>
                    &#10003;
                  </ServiceBtnCheck>
                </ServiceBtn>
              );
            })} */}
          {/* </ServiceGroup> */}
        {/* </InputWrapper> */}
      </FormGrid>

      <SubmitBtn type="submit">Send</SubmitBtn>
      {/* <div>
                <span>Prefer to call us? </span>
                <TelLink href="tel:8005906138">
                    (800) 590-6138
                </TelLink>
            </div> */}
      {/* <Button handleClick={test}>Test</Button> */}
    </form>
  );
};

export default QuoteForm;
